import React from 'react';
import Section from '../common/Section';
import Cart from '../common/Cart';
import lodash from 'lodash';


// When actions come into play, we will be able to see if the cart is built in the same way it was planned
const BuildCart = ({ rowCount, columnCount, totes }) => {
    const toteValues = React.useMemo(() => totes.map(({ tote }, pos) => {
        if (pos >= (lodash.size(totes))) {
            return null;
        }
        if (tote) {
            return tote;
        }
        return `${99900000000000 + (pos + 1)}`;
    }), [ totes ]);

    return (
        <Section title="Build Cart">
            <Cart
                buildCart
                rowCount={rowCount}
                columnCount={columnCount}
                toteValues={toteValues}
                isToteScanned={(totePos) => !!(totes[totePos]?.tote)}
                // the purpose of this is to show a small icon telling whether this is the actual value or proposed by the picking assistante
            />

            <div style={{ paddingLeft: 12 }}>
                <div style={{ backgroundColor: '#cdcdcd', width: '100%', height: 8 }} />
            </div>
        </Section>
    );
};


export default BuildCart;
