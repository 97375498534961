import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox, MfeDevPanel, Tab, Tabs, ThemedContainer } from '@takeoff-com/core-ui';
import serviceCatalog from './services/serviceCatalog';

import './App.scss';
import ManualFulfillment from './ManualFulfillment';
import MultiZonePicking from './MultizonePicking';
import { Box } from '@mui/material';

////////////////////////// The main page body ////////////////////////////////

const App = ({ config, defaultTask, defaultBarcodeConfig, defaultMultizoneTask, initialSelectedTab = 1 }) => {
    const [ configFormValues, setConfigFormValues ] = useState(config);
    const [ blurSvgs, setBlurSvgs ] = useState(false);

    const updateConfigFormValues = useCallback((newConfigFormValues) => {
        localStorage.setItem('last-config', JSON.stringify(newConfigFormValues));
        setConfigFormValues(newConfigFormValues);
    }, []);

    const mfeParameters = [
        {
            name: 'xToken',
            label: 'Token for authentication',
            configValueType: 'xtoken',
        },
        {
            name: 'retailerCodename',
            label: 'Code name of the retailer',
            configValueType: 'window',
            windowPath: 'RETAILER_CODENAME',
            defaultValue: 'maf',
        },
        {
            name: 'environmentTypeId',
            label: 'Env type: dev, qai, etc',
            configValueType: 'window',
            windowPath: 'ENVIRONMENT_TYPE_ID',
            defaultValue: 'dev',
        },
        {
            name: 'mfcId',
            label: 'MFC/Location Id',
            configValueType: 'userData',
            userDataKey: 'location',
            defaultValue: 'D02',
        },
        {
            name: 'baseDomain',
            label: 'ODE Base domain',
            defaultValue: 'tom.takeoff.com',
        },
    ];


    const [ barcodeConfig, setBarcodeConfig ] = useState(defaultBarcodeConfig);
    const { xToken, mfcId, retailerCodename, environmentTypeId, baseDomain } = configFormValues;

    useEffect(() => {
        document.getElementById('mfe-dev-panel-wrapper').firstChild.firstChild.click();
    }, []);

    const envConfig = useMemo(() => ({
        retailerCodename,
        environmentTypeId,
        baseDomain,
    }), [ retailerCodename, environmentTypeId, baseDomain ]);

    useEffect(() => {
        if (mfcId && xToken) {
            serviceCatalog.getBarcodeConfig({
                envConfig,
                mfcId,
                xToken,
            }).then((data) => {
                setBarcodeConfig(data);
            });
        }
    }, [ xToken, mfcId, envConfig ]);

    const [ selectedTab, setSelectedTab ] = React.useState(initialSelectedTab);

    const changeTab = (_event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <ThemedContainer className="App">
            <div id="mfe-dev-panel-wrapper">
                <MfeDevPanel
                    configValues={configFormValues}
                    setConfigValues={updateConfigFormValues}
                    parameters={mfeParameters}
                />
            </div>

            <Checkbox onChange={(e) => { setBlurSvgs(e.target.checked) }} label="Blur barcodes" checked={blurSvgs} />

            <div className={blurSvgs ? 'blur-svgs' : ''}>
                <Tabs onChange={changeTab} value={selectedTab}>
                    <Tab value={1} label="Single order" />
                    <Tab value={2} label="Multi order" />
                </Tabs>
                <Box className="top-container">
                    {selectedTab === 1 && (
                        <ManualFulfillment
                            envConfig={envConfig}
                            defaultTask={defaultTask}
                            barcodeConfig={barcodeConfig}
                            configFormValues={configFormValues}
                        />
                    )}
                    {selectedTab === 2 && (
                        <MultiZonePicking
                            defaultMultizoneTask={defaultMultizoneTask}
                            envConfig={envConfig}
                            barcodeConfig={barcodeConfig}
                            configFormValues={configFormValues}
                        />
                    )}
                </Box>
            </div>
        </ThemedContainer>
    );
};

export default App;
