import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { registerMountHandler, registerUnmountHandler } from '@takeoff-com/mfe-common';

import lodash from 'lodash';

import App from './App';

const AppWrapper = process.env.NODE_ENV === 'development' ? hot(App) : App;

registerMountHandler('picking-assistant', ({ contentId, params }) => {
    const { config } = params || {};

    const contentElement = document.getElementById(contentId);
    if (contentElement !== null) {
        let storedConfig;
        try {
            storedConfig = JSON.parse(localStorage.getItem('last-config'));
        }
        finally {
            const fullConfig = lodash.defaults({}, config, storedConfig || {});
            const configValues = lodash.pick(fullConfig, 'retailerCodename', 'environmentTypeId', 'xToken', 'mfcId');
            ReactDOM.render(<AppWrapper config={configValues} />, contentElement);
        }
    }
});

registerUnmountHandler('picking-assistant', ({ contentId }) => {
    const contentElement = document.getElementById(contentId);
    if (contentElement !== null) {
        ReactDOM.unmountComponentAtNode(contentElement);
    }
});
