import React from 'react';
import lodash from 'lodash';

import LoadingButton from './LoadingButton';
import { Box } from '@mui/material';

import { DateTimeLabel, Text } from '@takeoff-com/core-ui';
import mobileBackend from './services/mobileBackend';
import BuildCart from './multizone/BuildCart';
import PickingSteps from './multizone/PickingSteps';
import Staging from './multizone/Staging';


const MultiZonePicking = ({ envConfig, configFormValues, barcodeConfig }) => {
    const { xToken, mfcId } = configFormValues;

    const [ isGettingAvailableWork, setGettingAvailablework ] = React.useState(false);
    const [ getAvailableWorkError, setGetAvailableWorkError ] = React.useState();
    const [ activeZoneProfile, setActiveZoneProfile ] = React.useState();
    const [ task, setTask ] = React.useState();

    const getMultizoneAvailableWork = React.useCallback(() => {
        setGettingAvailablework(true);
        mobileBackend.getMultizoneAvailableWork({ envConfig, mfcId, xToken })
            .then((response) => {
                setTask(response.assignedTask);
                setActiveZoneProfile(response.activeZoneProfile);
            })
            .catch((err) => {
                setTask(undefined);
                setGetAvailableWorkError(err.response?.data?.error || err.message);
            })
            .finally(() => setGettingAvailablework(false));
    }, [ envConfig, mfcId, xToken ]);

    React.useEffect(() => {
        getMultizoneAvailableWork();
    }, [ getMultizoneAvailableWork ]);

    const zone = activeZoneProfile?.zones.find((z) => task?.zoneId === z.id);
    const rowCount = zone?.cartDimensions.rows;
    const columnCount = zone?.cartDimensions.cols;

    const stagingLocations = React.useMemo(() =>
        lodash.keys(
            lodash.reduce(task?.totes, (acc, tote) => {
                return {
                    ...acc,
                    [tote.expectedStagingLocation]: true,
                };
            }, {})
        ), [ task?.totes ]);


    return (
        <div>
            <LoadingButton
                width={400}
                title="Refresh"
                onClick={getMultizoneAvailableWork}
                loading={isGettingAvailableWork}
                error={getAvailableWorkError}
                dismissError={() => setGetAvailableWorkError()}
            />
            {task && zone && (
                <div>
                    <Box style={{ minWidth: 1000, borderTop: 'outset', borderColor: 'red' }}>
                        <Box style={{ fontSize: 50 }}>Picking Run in zone: {zone.name}</Box>
                        <Text format="h3-display" style={{ marginBottom: 12 }}>
                            {`Cart dimensions: ${zone.cartDimensions.cols} cols, ${zone.cartDimensions.rows} rows.`}
                        </Text>
                        <Text format="h3-display" style={{ marginBottom: 12, display: 'flex', flexDirection: 'row' }}>
                            Stage By:
                            <DateTimeLabel
                                style={{ marginLeft: 8 }}
                                format="h3-display"
                                dateTimeIsoString={task.stageBy}
                                dateFormat="dd-MMM-yyyy hh:mm"
                            />
                        </Text>
                    </Box>
                    <BuildCart
                        rowCount={rowCount}
                        columnCount={columnCount}
                        totes={task.totes}
                        actions={task.actions}
                    />
                    <PickingSteps task={task} barcodeConfig={barcodeConfig} configFormValues={configFormValues} />
                    {lodash.map(
                        lodash.sortBy(stagingLocations),
                        (stagingLocation) => (
                            <Staging
                                key={stagingLocation}
                                rowCount={rowCount}
                                columnCount={columnCount}
                                totes={task.totes}
                                stagingLocation={stagingLocation}
                            />
                        )
                    )}


                </div>
            )}

        </div>
    );
};


export default MultiZonePicking;
