/**
 * @module utils/services/serviceCatalog
 */

import axios from 'axios';
import lodash from 'lodash';
import queryString from 'query-string';
import { camelCaseKeys } from '../utils/toma';

const getBaseUrl = (envConfig) => {
    const baseDomain = envConfig.environmentTypeId === 'ode' ? envConfig.baseDomain : 'tom.takeoff.com';
    return `https://service-catalog-${envConfig.retailerCodename}-${envConfig.environmentTypeId}.${baseDomain}`;
};

const getConfigurationItems = async ({ envConfig, xToken, mfcId, categories }) => {
    const params = queryString.stringify({
        categories,
        level: 'mfc',
        'location-codes': mfcId,
        'value-format': 'json',
    });

    const response = await axios({
        method: 'GET',
        baseURL: getBaseUrl(envConfig),
        url: '/api/v1/configuration/config-items?' + params,
        headers: { 'X-Token': xToken },
    });
    return response.data;
};

const getBarcodeConfig = async ({ envConfig, xToken, mfcId }) => {
    const configItems = await serviceCatalog.getConfigurationItems({
        envConfig,
        mfcId,
        xToken,
        categories: [ 'ecom', 'features', 'pickerman' ],
    });

    return extractBarcodeConfiguration(configItems);
};

export const extractBarcodeConfiguration = (configItems) => {
    const config = lodash(configItems)
        .map(({ name, value, 'value-type': valueType }) => {
            if ((valueType === 'numeric' || valueType === 'integer') && !lodash.isNumber(value)) {
                value = lodash.toNumber(value);
            }
            if (valueType === 'boolean' && !lodash.isBoolean(value)) {
                value = value === 'true';
            }
            return [ lodash.camelCase(name), value ];
        })
        .fromPairs()
        .pick(
            'normalizationHasMiddleCheckDigit',
            'normalizationIsWeight',
            'normalizationNumberCount',
            'normalizationPower',
            'weightAggregated',
            'weightPrefix',
            'weightToleranceMinus',
            'weightTolerancePlus',
        )
        .tap((value) => value.normalizationIsWeight = value.normalizationIsWeight ? 'weight' : 'price')
        .mapKeys((_, key) => {
            return {
                normalizationHasMiddleCheckDigit: 'variableMeasureHasMiddleCheckDigit',
                normalizationIsWeight: 'variableMeasureAssumedType',
                normalizationNumberCount: 'variableMeasureNumberOfMeasureDigits',
                normalizationPower: 'variableMeasureNumberOfDecimalPlaces',
                weightAggregated: 'isAggregatedWeight',
                weightPrefix: 'variableMeasurePrefixes',
                weightToleranceMinus: 'weightToleranceMinus',
                weightTolerancePlus: 'weightTolerancePlus',
            }[key] || key;
        })
        .value()
    ;
    return config;
};


const getStagingLocations = async ({ envConfig, xToken, mfcId }) => {
    const params = queryString.stringify({
        'mfc-tom-code': mfcId,
    });

    const response = await axios({
        method: 'GET',
        baseURL: getBaseUrl(envConfig),
        url: '/api/v1/staging-locations?' + params,
        headers: { 'X-Token': xToken },
    });
    return camelCaseKeys(response.data || {}, true).stagingLocations || [];
};

const serviceCatalog = {
    getConfigurationItems,
    getBarcodeConfig,
    getStagingLocations,
};

export default serviceCatalog;
